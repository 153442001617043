import React, { createContext, useState } from 'react'

const AuthTokenContext = createContext({
  isVerified: false,
  verifyToken: () => {},
  logout: () => {},
  loading: false,
})

export const useAuthToken = () => {
  return React.useContext(AuthTokenContext)
}

export const AuthTokenProvider = ({ children }) => {
  const [isVerified, setIsVerified] = useState(false)
  const [loading, setLoading] = useState(true)

  const logout = () => {
    setIsVerified(null)
  }

  const verifyToken = async (token = null) => {
    setLoading(true)

    const form_action = '/api/verify-token'
    const request = await fetch(form_action, {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify({ token }),
    })

    const response = await request.json()
    setLoading(false)

    if (response.error) {
      logout()
      return false
    }

    setIsVerified(true)
    return true
  }

  return (
    <AuthTokenContext.Provider
      value={{ isVerified, verifyToken, logout, loading }}
    >
      {children}
    </AuthTokenContext.Provider>
  )
}

export default AuthTokenContext
