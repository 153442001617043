import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { AuthProvider } from './utils/context/AuthContext'
import { HarborCsThemeProvider } from './utils/context/HarborCsThemeProvider'
import { CookieConsentPopUpWrapper } from './components/CookieConsentPopUp'
import SearchContext from './components/Search/SearchContext'
import GatedContentHandler from './utils/GatedContentHandler'

// GLobal html classes
import './styles/globalHtmlClasses.scss'
import { ModalProvider } from './utils/context/ModalContext'
import SideNavigationContextProvider from './utils/context/sideNavigationContext'
import { AuthTokenProvider } from './utils/context/AuthTokenContext'

const wrapRootElement = (props) => {
  return (
    <>
      <AuthProvider>
        <AuthTokenProvider>
          <ModalProvider>
            <CookiesProvider>
              <HarborCsThemeProvider>
                <SideNavigationContextProvider>
                  <SearchContext.GoogleProvider>
                    <GatedContentHandler>{props.element}</GatedContentHandler>
                  </SearchContext.GoogleProvider>
                </SideNavigationContextProvider>
              </HarborCsThemeProvider>
            </CookiesProvider>
          </ModalProvider>
        </AuthTokenProvider>
      </AuthProvider>
      <CookieConsentPopUpWrapper />
    </>
  )
}
export default wrapRootElement
